//twk-home-page

.twk-homepage {
    .ms-tile-top {
        height: 1rem;
    }
    .twk-homepage-herocontentwrap {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
        .twk-page-wlcmcontent {
            h1, h2, h3, h4 {
                width: 80%;
                margin: auto;
                text-align: center;
                color: $twk-green-drk;
            }

            p {
                width: 90%;
                text-align: center;
                margin: auto;
                margin-top: 0.625rem;
                margin-bottom: 0.9375rem;
            }
        }
    }

    .twk-learnfromhome-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 0;
        & > .ms-tile-list__heading {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 2rem !important;
            font-size: 1rem;
            h1, h2, h3, h4 {
                color: $twk-green-drk;
                text-align: center;
                font-weight: bold;
            }
        }
        & .msc-ss-carousel {
            max-width: $msv-breakpoint-xl;
            margin: 0 auto;
        }
        .msc-ss-carousel-slide {
            justify-content: center;
            //Responsive UI
            @media (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
                flex-wrap: wrap;
            }

            .msc-ss-carousel-item {
                .ms-tile-list-container {
                    min-width: 17rem;
                    max-width: 22.6rem;
                    background-color: $white;

                    h2 {
                        white-space: normal;
                    }

                    .ms-tile-list__image {
                        padding-top: 68.25%;
                    }
                }

                img {
                    object-fit: cover;
                }
            }
        }
    }

    .twk-newsletter-tile-container {
        background-color: $moe-gray-200;
        padding: 1.5rem 0;

        .ms-tile-list__heading {
            display: flex;
            justify-content: center;
        }

        .msc-ss-carousel .msc-ss-carousel-strip {
            display: flex;
            justify-content: center;

            .ms-tile-list-container {
                width: 265px;

                h1,
                h2,
                h3 {
                    white-space: normal;
                }
            }
        }
    }

    .twk-productcollection-container {
        background-color: $twk-green-lgt;

        .twk-productcollection {
            h1,
            h2,
            h3 {
                display: flex;
                justify-content: center;
                color: $twk-green-mdm;
                font-weight: bolder;
            }
        }
    }

    .twk-newsletter-container {
        background-color: $white;
        padding: 1rem 2rem;

        .twk-newsletter-text {
            p {
                a {
                    color: $twk-green-mdm;
                }
            }
        }
    }
}

.twk-page-wlcmcontent {
    &__quote {
        display: flex;
        justify-content: center;
        color: $twk-green-mdm;
        line-height: calc(46 / 36);
        font-style: italic;
        font-size: 0.8rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        margin-top: 2rem;
        margin-bottom: 2rem;

        &::before {
            font-family: $msv-icon-font-family;
            font-weight: 900;
            content: '\f10d';
            display: block;
            width: 1rem;
            height: 1rem;
            padding-bottom: 1.5rem;
            background-repeat: no-repeat;
            background-size: 1em auto;
            margin-top: -2rem;
            font-size: 1.5rem;
        }
    }
}

.twk-kohangareo-container {
    .msc-ss-carousel {
        padding-bottom: 0;
        &-slide {
            justify-content: center;
        }
    }
    & .ms-tile-list-container {
        height: 35rem;
        & .ms-tile-list__heading {
            & h1, h2, h3 {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

.tile-lists-medium-img-main-container {
    max-width: 100% !important;
}