//twk cards

//Base Theme styles override for TWK site
.tile-lists-medium-img-main-container {
    margin-bottom: 0;
}

.twk-cards-list {
    & .ms-tile-list {
        &-container {
            height: 33.75rem;
            padding: 1.5rem;
            border-radius: 1.5rem !important;

            /*@media only screen and (max-width: $msv-container-max-width-s) {
                height: auto;
            }*/

            & .ms-tile-top {
                display: none;
            }
            & .ms-tile-text-arrow {
                width: 3.75rem;
                height: 3.75rem;
                border-radius: 50%;
                border: 2px solid $moe-gray-drkst;
                display: flex;
                justify-content: center;
                align-self: flex-start;
                padding: 0.75rem;
                position: absolute;
                left: 1.5rem;
                bottom: 1.5rem;
                //margin-bottom: 0.4rem;
                & svg {
                    width: 1.875rem;
                    height: 1.875rem;
                }
                &:hover {
                    border-color: $twk-green-drk;
                    & svg {
                        color: $twk-green-drk;
                    }
                }
            }
        }
        &__image {
            border-radius: 1rem !important;
            margin-bottom: 1rem;
            overflow: hidden;
            height: 13rem;
        }
        &__heading {
            max-height: 17.5rem;
            padding: 0 !important;
            margin-bottom: 0 !important;
            & h1, h2, h3, h4 {
                font-size: 1.45rem;
                margin-top: 0.2rem;
                margin-bottom: 0.3rem;
                line-height: 110%;
                color: $twk-txt;
                &:first-child {
                    font-weight: normal;
                }
            }
        }
        &__text {
            // height: 12.5rem;
            font-size: 1rem;
            & p {
                margin-top: 1rem;
            }
        }
    }
    & > .ms-tile-list__heading {
        h2 {
            //Desktop 1280px and above
            @media only screen and (min-width: $msv-breakpoint-mxl) {
                font-size: 2.625rem; //42px
            }
        }
    }
    
//Te Whāriki Online Landing view Decorative Tiles
    &--decorative {
        & > .ms-tile-list__heading {
            margin-left: 0;
            h1, h2, h3, h4, h5, h6 {
                font-weight: 700 !important;
                text-align: center;
                margin-top: 1rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                //Mobile 767px and below
                @media only screen and (max-width: $msv-container-max-width-s) {
                    font-size: 1.75rem !important; //28px                    
                }
            }
            h1, h2, h3, h4 {
                color: $twk-green-mdm;
            }
            h1 {
                font-size: 3.75rem; //60px                
                //Desktop 1280px and above
                @media only screen and (min-width: $msv-breakpoint-mxl) {
                    font-size: 5rem; //80px
                    color: $twk-txt;
                }
            }
            h2 {
                font-size: 2.625rem; //42px
            }
            h3 {
                font-size: 1.9375rem; //31px
            }
            h4 {
                font-size: 1.375rem; //22px
            }
        }

        .msc-ss-carousel {
            padding-left: 0;
            padding-right: 0;
            &-strip {
                .msc-ss-carousel-slide {
                    max-width: 62.10526rem;
                    margin: auto;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-flow: row wrap;
                    flex-flow: row wrap;

                    .msc-ss-carousel-item {
                        border-radius: 2.5rem;
                        transition: all 0.3s;

                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 auto;
                        flex: 0 0 auto;
                        width: calc(100% - 1.5rem);
                        margin-left: .75rem;
                        margin-right: .75rem;
                        margin-bottom: 1.5rem;

                        @media only screen and (min-width: 40rem) {
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 auto;
                            flex: 0 0 auto;
                            width: calc(50% - 3rem);
                            margin-left: 1.5rem;
                            margin-right: 1.5rem;
                            margin-bottom: 3rem;
                        }

                        &:hover {
                            opacity: 0.85;
                        }

                        .moe-tile-medium-image-container {
                            .ms-tile-list-container {
                                padding: 0;
                                border: 0;
                                width: 100% !important;
                                height: 40rem;
                                border-radius: 2.5rem !important;

                                @media only screen and (min-width: $msv-container-max-width-l) {
                                    //width: 30rem !important;
                                    height: 42rem;
                                }

                                .ms-tile-list__image {                                    
                                    padding: 0;
                                    margin: 2.25rem 0;
                                    background-color: transparent !important;
                                    //Base Desktop and above
                                    @media only screen and (min-width: $msv-container-min-width-l) {
                                        min-height: 15.625rem;
                                    }
                                    
                                    img {
                                        object-fit: cover;
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                .ms-tile-list__heading {
                                    margin-top: -1rem;
                                    height: max-content;
                                    max-height: 24rem;

                                    h1, h2, h3, h4, h5, h6 {
                                        text-align: center;
                                        text-align: center;
                                        overflow: hidden;
                                        font-size: 1.63158rem;
                                        line-height: 1.32258;
                                        text-overflow: ellipsis;
                                        font-weight: 700;
                                    }

                                    .ms-tile-list__text {
                                        width: 75%;
                                        margin: auto;
                                        text-align: center;

                                        p {
                                            font-size: 1.26rem;
                                            font-weight: 400;
                                        }
                                    }

                                    .ms-tile-text-arrow {
                                        transition: all 0.3s;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        // margin-top: -7rem;
                                        position: absolute;

                                        // @media only screen and (min-width: $msv-container-max-width-l) {
                                        //     margin-top: -5rem;
                                        // }
                                    }
                                }
                            }
                        }
                    }

                    .msc-ss-carousel-item:first-child {
                        background-color: $twk-landing-page-decorative-tile1-bg-color;
                        transition: all 0.3s;
                        //margin-right: 3rem;

                        .ms-tile-list-container {
                            background-color: $twk-landing-page-decorative-tile1-bg-color;
                        }

                        &:hover .ms-tile-text-arrow {
                            color: $twk-landing-page-decorative-tile1-color !important;
                            border-color: $twk-landing-page-decorative-tile1-color;
                        }

                        .ms-tile-text-arrow:hover {
                            color: $twk-landing-page-decorative-tile1-color !important;
                            border-color: $twk-landing-page-decorative-tile1-color;

                            & svg {
                                color: $twk-landing-page-decorative-tile1-color;
                            }
                        }

                        .ms-tile-list__title {
                            transition: all 0.3s;
                        }

                        &:hover .ms-tile-list__title {
                            text-decoration: underline;
                        }

                        .ms-tile-list__heading {
                            h1, h2, h3, h4, h5, h6 {
                                color: $twk-landing-page-decorative-tile1-color;
                                width: 70%;
                                margin: auto;
                            }
                        }

                        img {
                            background: $twk-landing-page-decorative-tile1-bg-color;
                        }
                    }

                    .msc-ss-carousel-item:last-child {
                        background-color: $twk-landing-page-decorative-tile2-bg-color;
                        transition: all 0.3s;

                        .ms-tile-list-container {
                            background-color: $twk-landing-page-decorative-tile2-bg-color;
                        }

                        &:hover .ms-tile-text-arrow {
                            color: $twk-landing-page-decorative-tile2-color !important;
                            border-color: $twk-landing-page-decorative-tile2-color;
                        }

                        .ms-tile-text-arrow:hover {
                            color: $twk-landing-page-decorative-tile2-color !important;
                            border-color: $twk-landing-page-decorative-tile2-color;

                            & svg {
                                color: $twk-landing-page-decorative-tile2-color;
                            }
                        }

                        .ms-tile-list__title {
                            transition: all 0.3s;
                        }

                        &:hover .ms-tile-list__title {
                            text-decoration: underline;
                        }

                        .ms-tile-list__heading {
                            h1, h2, h3, h4, h5, h6 {
                                color: $twk-landing-page-decorative-tile2-color;
                                width: 70%;
                                margin: auto;
                            }
                        }

                        img {
                            background: $twk-landing-page-decorative-tile2-bg-color;
                        }
                    }
                }
            }
        }

        &-text {
            margin: 0 auto;
            max-width: 51.26316rem;
            text-align: left !important;
            font-size: 1.125rem;

            p {
                text-rendering: optimizeLegibility;
                line-height: 1.6;
                font-weight: 400;                
                -webkit-font-smoothing: antialiased;
                color: #231f20;
            }
        }

        &-small {
            margin-bottom: 2rem;
            & > .ms-tile-list__heading {
                margin-left: 0;
                h1, h2, h3, h4, h5, h6 {
                    font-weight: 700 !important;
                    text-align: center;
                    color: $twk-green-mdm;   
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    //Mobile 767px and below
                    @media only screen and (max-width: $msv-container-max-width-s) {
                        font-size: 1.5rem !important; //24px
                    }                 
                }
                h1 {
                    font-size: 3.75rem; //60px
                }
                h2 {
                    font-size: 2.625rem; //42px
                }
                h3 {
                    font-size: 1.9375rem; //31px
                }
                h4 {
                    font-size: 1.375rem; //22px
                }
                h5, h6 {
                    color: $twk-txt;
                }
            }

            .msc-ss-carousel {
                &-strip {
                    .msc-ss-carousel-slide {
                        justify-content: center;
                        //Tablet landscape and base desktop
                        @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-container-max-width-ml) {
                            justify-content: left;
                        }
                        .msc-ss-carousel-item {
                            transition: all 0.3s;

                            &:hover {
                                opacity: 0.85;
                            }

                            .ms-tile-text-arrow {
                                transition: all 0.3s;
                                position: absolute;
                                bottom: 0;
                            }

                            &:hover .ms-tile-text-arrow {
                                color: $twk-landing-page-decorative-small-tile-color !important;
                                border-color: $twk-landing-page-decorative-small-tile-color;
                            }

                            .ms-tile-list__title {
                                display: -webkit-box;
                                overflow: hidden;
                                font-size: 1.15789rem;
                                line-height: 1.25;
                                text-overflow: ellipsis;
                                font-weight: 700;
                                color: $twk-txt;
                            }

                            &:hover .ms-tile-list__title {
                                text-decoration: underline;
                            }

                            .ms-tile-text-arrow:hover {
                                color: $twk-landing-page-decorative-small-tile-color !important;
                                border-color: $twk-landing-page-decorative-small-tile-color;

                                & svg {
                                    color: $twk-landing-page-decorative-small-tile-color;
                                }
                            }

                            .moe-tile-medium-image-container {
                                .ms-tile-list-container {
                                    padding: 0 0 1.5rem 0;
                                    border-radius: 1.26rem;
                                    border: 0;
                                    width: 12rem !important;
                                    height: 22.375rem;
                                    min-width: 11rem;
                                    min-height: 22.375rem;

                                    @media only screen and (min-width: $msv-container-max-width-l) {
                                        width: 12rem !important;
                                        height: 22.375rem;
                                    }

                                    .ms-tile-list__image {
                                        height: 8rem;
                                        width: 100%;
                                        margin: 0;
                                        border-radius: 0 !important;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center;
                                        }
                                    }

                                    .ms-tile-list__heading {
                                        position: relative;
                                        height: 14rem;
                                        padding: 0 1.5rem !important;

                                        .ms-tile-text-arrow {
                                            left: 1.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
