//twk-header

.ms-header {
    padding-top: 0;
    &__container,
    &-pattern-image,
    &__desktop-view .ms-nav {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
    }
    &__container,
    &__topbar {
        padding-bottom: 0;
        padding-top: 0;
        height: auto !important;
    }
    &__topbar {
        & .moe-header__desktop {
            padding: 0;
            display: flex;
            width: 100%;
            justify-content: space-between;
            &-left,
            &-right {
                display: flex;
                align-items: center;
            }
            &-right {
                & .moe-header-wrapper {
                    margin: 0 1.5rem;
                    margin-top: 0.625rem
                }
                & .moe-header-site-redirect {
                    & a,
                    & a img {
                        height: 7.25rem;
                        width: 7.25rem;
                    }
                }
            }
        }
    }

    // &__logo a,
    // &__logo a img {
    //     height: 7.25rem;
    // }
}

.desktop-vp.ms-nav {
    & > .ms-nav__list:first-child {
        & > .ms-nav__list__item {
            & > .ms-nav__list__item {
                &__button,
                &__link {
                    //Base and medium responsive UI
                    @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
                        width: 7.8rem !important;
                    }
                }
            }
        }
    }
}
.header-patternimage-wrap,
.ms-header-pattern-image {
    display: none;
}

//TWK Header Pattern
.twk-header {
    &-wrap {
        margin-bottom: 0.625rem;
    }
}
.twk-header-pattern-wrap {
    padding: 0;
    margin: 0;
    background-color: #fff;
    min-height: 5rem !important;
    height: 8rem !important;
    background-size: auto 140% !important;
    background-image: url('https://images-au-prod.cms.commerce.dynamics.com/cms/api/qsjhkgqqvf/imageFileData/MLigk?pubver=1');
    background-repeat: repeat-x;
    background-position: top !important;
    overflow: hidden;
}

.twk-header-pattern {
    padding: 0;
    margin: 0;
    background-color: #fff;

    .ms-container-background-image {
        min-height: 5rem !important;
        height: 6rem !important;
        background-size: auto 140% !important;
        background-position: top !important;
        overflow: hidden;
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-xs) {
            min-height: 2rem !important;
            height: 3rem !important;
        }
        //Tablet Responsive UI
        @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-container-max-width-m) {
            min-height: 3.5rem !important;
            height: 4.5rem !important;
        }
    }
}

//MOE Global Hamburger Menu for Mobile and Tablet
@media (max-width: $msv-breakpoint-l) {
    .ms-header__collapsible-hamburger {
        & .ms-nav .ms-nav__drawer {
            & .drawer__button,
            & .ms-nav__list__item__link {
                &:focus {
                    background-color: $twk-green-lgt !important;
                }
                & .drawer__glyph__end {
                    color: $twk-green-drk !important;
                }
            }
        }
    }
}
