//twk-wellbeing-page

//Ratings and Reviews
.ms-ratings-histogram.d-flex {
    & .ms-ratings-histogram__ratings {
        display: inline-flex;
        &__reviews {
            margin: 0 0.625rem;
        }
    }
}

//Generic
.add-links {
    margin: 1rem 0;
    & .row .col-auto {
        width: 12rem;
    }
    & .add-links-item {
        & .row .col-12 {
            max-width: fit-content;
        }
        & .ms-text-block,
        & p {
            margin: 0;
        }
    }
}

//Page Welcome Content
.twk-page-wlcmcontent {
    & .twk-page-ttl-wrap,
    & .twk-section-ttl,
    & h1, h2, h3 {
        color: $twk-green-mdm;
        margin-top: 0.625rem;
        margin-bottom: 0.9375rem;
    }
    & .twk-page-ttl-wrap {
        text-align: center;
    }
    & .twk-page-ttl-wrap h2 {
        margin-top: 0;
        margin-bottom: 0;
    }
    & .twk-page-ttl-wrap p,
    & .twk-section-des {
        color: #000000;
    }
    ul {
        list-style: none;
    }
}

//Media
.twk-videocont-wrap-lg,
.twk-imagecont-wrap-lg {
    min-width: 28.125rem;
    max-width: 60.875rem; //974px as per live site
    margin: 0 auto;

    //Responsive UI
    @media (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-s) {
        min-width: fit-content;
    }
}
.twk-videocont-wrap-lg .ms-video__title-container {
    display: none;
}
.twk-imagecont-wrap-lg {
    & .ms-image-list__list-item {
        max-width: 40.625rem;
        margin: 0 auto !important;
    }
    & img {
        width: 100%;
        height: auto !important;
    }
    .twk-image-wrap-lg {
        img {
            width: 500px;
        }
    }
}
