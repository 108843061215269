.twk-landingpage-pattern {
    .twk-footer__row-pattern {
        .twk-footer-pattern {
            margin: 0;
        }
    }
}

.twk-main {
    &--nomenu {
        & .moe-nav-wrap,
        & .moe-header-actions,
        & .moe-header__desktop-container-right {
            display: none;
        }
        & main {
            min-height: 40rem;
        }
        & .twk-cards-list--decorative {
            & .ms-tile-list__heading {
                max-width: $msv-breakpoint-xl;
                margin: 0 auto;
            }
        }
        & .twk-cards-list--decorative-small {
            margin-bottom: 0;
        }
    }
}
