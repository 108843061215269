//twk-section

.moe-field {
    &--mx-w974.moe-image-wrap {
        height: auto;
        & .moe-image {
            width: 100%;
            height: auto;
        }
    }
}
//All primary and secondary titles
.ms-image-list__title,
.ms-accordion-header-section__heading,
.container-heading,
.twk-section__ttl,
.moe-vimeo-player__ttl {
    color: $twk-green-mdm;
    //fix: bug 103592 - heading fix for vimeo player and container heading
    line-height: 1;
    //END
}

h5,
h6 {
    &.ms-image-list__title,
    &.ms-accordion-header-section__heading,
    &.container-heading,
    &.twk-section__ttl,
    &.moe-vimeo-player__ttl {
        color: $twk-txt;
    }
}

//section
.twk-section {
    &-ttl-wrap {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
        & h1,
        h2,
        h3 {
            color: $twk-green-mdm;
            margin-top: 0.625rem;
            margin-bottom: 0.9375rem;
        }
    }
    background-color: #fff;
    padding: 1rem;
    //border-bottom: 1px solid #707070;
    &-alt {
        background-color: $twk-green-lgt;
        padding: 1rem;
        //border-bottom: 1px solid #707070;
        &-bg-pattern {
            margin: 0;
            background-color: $twk-green-lgt;
            background-size: 7rem auto !important;
            background-image: url('../../../images/twk-alt-row-bg.png');
            background-repeat: repeat-x;
            background-position: bottom !important;
            & > .ms-tile-list__heading,
            & .msc-ss-carousel {
                max-width: $msv-breakpoint-xl;
                margin: 0 auto !important;
            }
        }
    }

    &__content {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        a {
            line-height: 1.5;
            color: #60b74e;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: #51a041;
            }
        }

        &--center {
            max-width: 54rem;
            margin: auto;
        }
    }
}

.twk-field {
    &--addresource {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
        padding: 0;
        & h1,
        h2,
        h3 {
            color: $twk-green-mdm;
        }
    }
}
