//twk-reset

//Heading tags font sizes reset as per Te Whariki live site
h1 {
    font-size: 3.75rem; //60px
    //fix: bug 103592 - fix h1 cut off issue in smaller screens
    @media screen and (max-width: $msv-breakpoint-m) {
        font-size: 3rem;
    }
    // END
}
h2 {
    font-size: 2.625rem; //42px
}
h3 {
    font-size: 1.9375rem; //31px
}
h4 {
    font-size: 1.375rem; //22px
}
h5 {
    font-size: 1.125rem; //18px
}
h6 {
    font-size: 1rem; //16px
}
h1, h2, h3, h4 {
    color: $twk-green-mdm;
}
h5, h6 {
    color: $twk-txt;
}