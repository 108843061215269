//twk-navigation-menu

/*TWK Global Top Menu Custom Styles starts*/
/*Temp Style*/
.ms-nav > .ms-nav__list > .ms-nav__list__item:first-child {
    display: none;
}
.ms-nav__drawer-close__glyph__end {
    &::after {
        @include msv-icon();
        font-size: 1rem;
        width: 1rem;
        content: $msv-arrow-right;
        color: $twk-green-mdm;
        transform: none;
    }
    &:hover {
        &::after {
            color: $twk-txt;
            transform: none;
        }
    }
}

/*Temp Style*/
.desktop-vp {
    &.ms-nav {
        height: 6rem;
        max-width: fit-content;
        margin: 0 auto;
        //Base Desktop Responsive UI between 1024px and 1280px
        @media only screen and (min-width: $msv-breakpoint-ml) and (max-width: $msv-breakpoint-mxl) {
            max-width: 100% !important;
        }

        & > .ms-nav__list {
            height: 6rem;
            background: transparent;
            background-color: transparent;
            margin: 0px auto 0 7.5rem;
            //Mobile, Tablet and Base Desktop Responsive UI
            @media only screen and (max-width: $msv-container-max-width-l) {
                margin-left: 0;
            }
            & > .ms-nav__list__item {
                & > .ms-nav__list__item {
                    &__button {
                        max-width: 9.375rem;
                        height: 100%;
                        font-size: 0.875rem;
                        font-weight: bold !important;
                        display: inline-flex;
                        align-items: center;
                        padding: 0 1rem;
                    }
                }
            }
            & .ms-nav__list__item {
                margin: 0;
                min-height: 2rem;
                height: auto !important;
            }
            & > .ms-nav__list__item {
                &:hover,
                & .ms-nav__list__item__button[aria-expanded='true'] {
                    background-color: $twk-green-lgt;
                }
                & .ms-nav__list__item__button,
                & .ms-nav__list__item__link {
                    &::before,
                    &:focus::before {
                        display: none;
                    }
                    width: 100%;
                    height: 100%;
                    font-size: 0.875rem;
                    font-weight: normal;
                    color: $twk-txt;
                }
                & .ms-nav__list__item__link {
                    text-align: center;
                    padding: 0.625rem 1rem;
                    display: grid;
                }
                & *,
                .ms-nav__deafult {
                    background: transparent;
                    background-color: transparent;
                    color: $twk-txt;
                    white-space: normal;
                    line-height: 120%;
                }
                & > .submenu_link {
                    height: 100%;
                }
            }
        }

        & .ms-nav {
            &__deafult *,
            &__list.level-2 * {
                font-size: 0.875rem;
                font-weight: normal;
                color: $twk-txt;
            }
            &__deafult {
                & > .ms-nav__list .havesubmenu .submenu_link {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }
            &__list.level-2 {
                & .ms-nav__list__item {
                    & .ms-nav__list__item__link,
                    & .ms-nav__list__item__span {
                        display: block;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        padding-top: 0.375rem;
                        padding-bottom: 0.375rem;
                    }
                }
            }
        }

        /*Custom Menu Drop down as per Mockup starts*/
        & > .ms-nav__list {
            & > .ms-nav__list__item {
                & > .ms-nav__deafult {
                    position: absolute;
                    width: 100%;
                    top: 6rem;
                    right: 0;
                    height: 22rem;
                    background-color: $twk-green-lgt !important;
                    padding: 1rem;
                    overflow: auto;
                    & .ms-nav__list__item__link {
                        display: block;
                        text-align: left;
                    }
                    & > .ms-nav__list:first-child {
                        & > .ms-nav__list__item {
                            & > .ms-nav__list__item__link {
                                position: relative;
                            }
                        }
                    }
                }

                & .ms-nav__deafult {
                    & > .ms-nav__list {
                        & .havesubmenu {
                            & > .submenu_link {
                                & .ms-nav__list__item__link,
                                & .ms-nav__list__item__span,
                                & span {
                                    height: auto !important;
                                    font-size: inherit;
                                    font-weight: normal;
                                }
                            }
                            & .ms-nav__deafult {
                                margin-top: 0;
                                left: 0;
                                & > .ms-nav__list {
                                    border: none !important;
                                }
                            }
                        }
                    }
                }
            }
            & .level-2 {
                overflow: visible !important;
                left: inherit;
            }
        }

        & .ms-nav__list li .ms-nav__deafult ul {
            width: 17rem;
            //Base Desktop Responsive UI between 1024px and 1280px
            @media only screen and (min-width: $msv-breakpoint-ml) and (max-width: $msv-breakpoint-mxl) {
                width: 15rem;
            }
            & .ms-nav__list__item {
                &__button,
                &__link {
                    width: 12rem;
                    white-space: nowrap;
                }
            }
            & li .ms-nav__deafult {
                position: absolute;
                left: 17rem;
                //Base Desktop Responsive UI between 1024px and 1280px
                @media only screen and (min-width: $msv-breakpoint-ml) and (max-width: $msv-breakpoint-mxl) {
                    left: 15rem;
                }
                top: 0;
                & .ms-nav__list__item {
                    &__button,
                    &__link {
                        width: 12rem;
                        white-space: nowrap;
                    }
                }
            }
        }

        /*Custom Menu Drop down as per Mockup ends*/
    }
    /*TWK Global Top Menu Custom Styles ends*/
}

.submenu_link {
    position: relative;
    padding-right: 0;
    & .ms-nav__list__item__link {
        padding-right: 0.375rem;
    }
    &--ttl {
        min-width: 19rem;
        @media screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-container-max-width-l) {
            min-width: 15rem; //Bug 91468 Fix
        }
        & .ms-nav__list__item__link {
            display: block !important;
            padding-left: 0 !important;
        }
        & a:hover {
            text-decoration: none !important;
        }
        & .moe-icon-rightarrow {
            margin-left: 0;
        }
        & .submenu_link--ttl {
            border: none !important;
            & .moe-icon-rightarrow {
                display: none !important;
            }
        }
    }
}
.submenu_link,
.ms-nav__list__item,
.ms-nav__list__item__link {
    width: 100% !important;
    & .moe-txt-english {
        font-weight: 700 !important;
    }
    & h3 {
        font-size: 1.375rem !important;
    }
}
.ms-nav__deafult {
    & .ms-nav__list {
        &.level-2.twk-nav__list {
            position: absolute;
            left: 22rem !important;
            //Base Desktop Responsive UI between 1024px and 1280px
            @media only screen and (min-width: $msv-breakpoint-ml) and (max-width: $msv-breakpoint-mxl) {
                left: 16rem !important;
            }
            top: 0;
            & .ms-nav__list__item {
                border-bottom: 1px solid $twk-green-mdm;
                padding-bottom: 10px;
            }
        }
    }
}
.ms-nav {
    & > .ms-nav__list {
        & > .ms-nav__list__item:first-child {
            & .ms-nav__deafult {
                & > .level-2 {
                    & > .ms-nav__list__item {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

//twk-nav
//Mobile and Tablet
.ms-header__collapsible-hamburger {
    & .ms-nav > .ms-nav__drawer .drawer__button {
        height: 4rem;
        & + .collapse.show {
            padding-bottom: 1rem;
        }
    }
    & .submenu_link .ms-nav__list__item__link {
        display: block !important;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        height: auto;
    }
}
