// twk-breadcrumb

//Bse theme override for TWK breadcrumb
.ms-breadcrumb {
    & .ms-breadcrumb_list {
        justify-content: center;
    }    
    & .ms-breadcrumb_item {
        & .ms-breadcrumb_link {
            color: $twk-green-drk;
        }
    }
}

.twk-breadcrumb {
    display: flex;
    align-items: center;
    padding:1.25rem 1.5rem;
    & .ms-breadcrumb_list {
        margin: auto;
    }
    & .ms-breadcrumb_link {
        font-weight: 600;
        color: $twk-green-mdm;
    }
}