//twk search result container
.ms-search-result-container {
    margin-top: 1rem;
    .ms-search-result-container {
        &__product-section {
            background-color: $twk-green-lgt;
        }
    }
}

.product-item-main-container .msc-product:hover .ms-product-item-top {
    background-color: $twk-green-drk !important;
}

h1, h2, h3, h4 {
    &.ms-product-collection__heading {
        color: $twk-green-drk;
    }
}
