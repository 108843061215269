//twk color variables

//TWK Primary colors
//$twk-green-lgter: #f2f7ea; 
$twk-green-lgt: #eef5e5;
$twk-green-mdm: #60b74e;
$twk-green-drk: #1C8341;
$twk-blue-lgt: #e6f5f7;
$twk-blue: #1e909c;
$twk-purple-lgt: #ededf5;
$twk-purple: #5f5f9f; 
$twk-txt: #231f20;

//TWK Decorative colors
$twk-landing-page-decorative-tile1-color: $twk-purple;
$twk-landing-page-decorative-tile2-color: $twk-green-mdm;
$twk-landing-page-decorative-tile1-bg-color: $twk-purple-lgt;
$twk-landing-page-decorative-tile2-bg-color: $twk-green-lgt;
$twk-landing-page-decorative-small-tile-color: $twk-green-mdm;
 
 



