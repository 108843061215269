//twk-footer
footer {
    margin-top: 0;
}
.twk-footer-pattern {
    padding: 0;
    margin: 2rem 0 0 0;
    background-color: #fff;
    & .ms-container-background-image {
        min-height: 5rem !important;
        height: 8rem !important;
        background-size: auto 140% !important;
        background-position: bottom !important;
        overflow: hidden;

        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-xs) {
            min-height: 2rem !important;
            height: 3rem !important;
        }
        //Tablet Responsive UI
        @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-breakpoint-mxl) {
            min-height: 3.5rem !important;
            height: 4.5rem !important;
        }
    }
}
