// Pages for Te Whariki
@import 'twk-home-page';
@import 'twk-wellbeing-page';
@import 'twk-landing-page';
@import 'twk-resource-page';


//Global Common Styles and Overrides of Base Theme starts
/*max-width Content area as per NZ Education Design System --start*/
body,
body main {
    padding: 0;
    margin: 0 auto;
    background-color: $white;
}
body main {
    max-width: $msv-breakpoint-xl;
}

/*max-width Content area as per NZ Education Design System --end*/
.twk-homepage,
.twk-main--nomenu,
.tkr-body {
    & main,
    & .twk-search-wrap {
        max-width: none;
    }
}

p {
    margin-top: 0.625rem;
    margin-bottom: 0.9375rem;
}
.ms-header__desktop-view {
    background-color: #fff;
    border-bottom: 1px solid #707070;
    padding: 0 0 1px 0;
}
//Global Common Styles and Overrides of Base Theme Ends
