//twk-resource-page

.twk-page-wlcmcontent {
    &wrap {
        max-width: 60.875rem; //974px as per live site
        background-color: #fff;
        color: #000000;
        margin: 0 auto;
        text-align: center;
    }
    min-width: 40rem;
    max-width: 60.875rem; //974px as per live site
    padding: 1rem;
    margin: 0 auto 1.25rem auto;
    //Base Desktop Responsive UI 1024px and above
    @media only screen and (min-width: $msv-breakpoint-ml) {
        padding-left: 0;
        padding-right: 0;
    }
    //Mobile and Tablet Responsive UI
    @media (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-ml) {
        min-width: 100%;
        max-width: 100%;
    }
}