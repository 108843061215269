//twk search

.twk-search {
    &-wrap {
        & .ms-search {
            background-color: $twk-green-drk;
            &__searchForm {
                outline: none !important;
            }

            &__form-control::placeholder {
                font-size: 10px;
            }
        }
    }
    &-communities-col-lft {
        background-color: #a4d19d;
    }
    &-communities-col-rgt {
        background-color: #2e988c;
    }
}

.ms-search__form-control::placeholder {
    font-size: 10px;
}
